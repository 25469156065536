<template>
  <div class="home">
    <router-link to="/" class="header-logo">
      <span>M.A.D</span>
      <span>AGENCY</span>
    </router-link>
    <div class="perspective-container">
      <div class="bottom-background"></div>
    </div>
    <div class="description-home">
      <div class="description-text">
        <h1>L'agence web <span class="important">Lilloise</span> par excellence !</h1>
        <p>M.A.D Agency est l'agence web qu'il vous faut si vous recherchez :</p>
        <p>La productivité</p>
        <p>La créativité</p>
        <p>Un accompagnement <span class="important">humain</span> !</p>
        <p>La bonne humeur du <span class="important">Nord</span> !</p>
        <p>
          Toutes nos réalisations sont faites "from scratch" et avec amour pour <br/>
          correspondre à chacun de vos besoins et n'avoir aucune limite !
        </p>
      </div>
      <div class="description-illustration">
      </div>
    </div>
    <div class="solutions-home">
      <h1>Nos solutions</h1>
      <p>Nous vous proposons plusieurs solutions pour vos sites web !</p>
      <div class="solutions-list">
        <div class="solution">
          <div class="solution-icon">
            <img src="/images/vitrine.png"/>
          </div>
          <h3>Site vitrine</h3>
          <p>
            Nous proposons un service de site vitrine qui comprend l'hébergement et la maintenance.
            Très utile pour avoir vos infos en ligne à moindre frais !
          </p>
        </div>
        <div class="solution">
          <div class="solution-icon">
            <img src="/images/creative.png"/>
          </div>
          <h3>Votre site personnel</h3>
          <p>
            Ce service vous propose un site qui correspondra à toutes vos attentes !
            Bien entendu il sera nécessaire d'établir un devis alors prenons contact !
          </p>
        </div>
        <div class="solution">
          <div class="solution-icon">
            <img src="/images/ecommerce.png"/>
          </div>
          <h3>Site e-commerce</h3>
          <p>
            C'est grace à ce service que vous pourrez étendre votre clientèle à toute la france ou plus !
            On parle ici de la mise en place d'un site à votre image avec ses articles paramétrables par vos soins !
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);

export default {
  name: 'HomeView',
  methods: {
    handleMouseEnterImportant(e) {
      const navItem = e.currentTarget;
      const navItemBox = navItem.getBoundingClientRect();
      this.$root.mouse.x = Math.round(navItemBox.left + navItemBox.width / 2);
      this.$root.mouse.y = Math.round(navItemBox.top + navItemBox.height / 2);
      this.$root.sticky = true;
      gsap.to('.ball', {
        borderRadius: 0,
        width: navItemBox.width,
        height: navItemBox.height,
        backgroundColor: "transparent",
        duration: .01,
      })
    },
    handleMouseEnterSolution(e) {
      const navItem = e.currentTarget;
      const navItemBox = navItem.getBoundingClientRect();
      this.$root.mouse.x = Math.round(navItemBox.left + navItemBox.width / 2);
      this.$root.mouse.y = Math.round(navItemBox.top + navItemBox.height / 2);
      this.$root.sticky = true;
      gsap.to('.ball', {
        borderRadius: "20px",
        width: navItemBox.width - 2,
        height: navItemBox.height - 2,
        backgroundColor: "transparent",
        duration: .01,
      })
    },
    handleMouseLeave() {
      this.$root.sticky = false;
      gsap.to('.ball', {
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        zIndex: 9,
        duration: .01,
      })
    }
  },
  mounted() {
    let headerLogoPosition = {left: "6%", top: "6%", scale: .21};
    if(this.$root.isMobile){
      headerLogoPosition = {left: "12%", top: "4%", scale: .3};
    }
    headerLogoPosition.x = 0;
    headerLogoPosition.y = 0;
    gsap.set(".header-logo", {
      position: "fixed",
      top: "50%",
      left: "50%",
      xPercent: -50,
      yPercent: -50,
      zIndex: 10
    });
    gsap.to(".header-logo", {
      ...headerLogoPosition,
      scrollTrigger: {
        trigger: '.header-logo',
        scrub: 0,
        start: "-=100",
        end: "+=600",
      }
    });
    gsap.set(".bottom-background", {
      height: "100%",
      width: "100%",
      top: 0,
      position: "fixed",
      transformPerspective: "200vh",
      rotateX: "45deg",
      scale: 3,
    });
    gsap.to(".bottom-background", {
      scale: 2.5,
      scrollTrigger: {
        trigger: '.home',
        scrub: 0,
        start: "top top",
        end: "+=800",
      }
    });
    gsap.to(".description-home", {
      position: "sticky",
      top: 0,
      left: 0,
      scrollTrigger: {
        trigger: '.home',
        scrub: 0,
        start: "+=" + this.$root.vh(100),
        end: "+=" + this.$root.vh(200),
      }
    });
    gsap.to(".description-illustration", {
      width: "40%",
      opacity: .3,
      scrollTrigger: {
        trigger: '.description-home',
        scrub: 0,
        start: "top",
        end: "+=" + this.$root.vh(50),
      }
    });
    gsap.set(".description-text", {
      position: "absolute",
      top: "-100%",
      left: "50%",
      xPercent: -50,
      yPercent: -50,
      x: 0,
      y:0,
      zIndex: 100,
    });
    gsap.to(".description-text", {
      top: "50%",
      scrollTrigger: {
        trigger: '.description-home',
        scrub: 0,
        start: "top",
        end: "+=" + this.$root.vh(50),
      }
    });
    gsap.set(".solutions-home", {
      position: "absolute",
      zIndex: 2,
      top: this.$root.vh(300),
    });
    gsap.to(".solutions-home", {
      position: "sticky",
      top: 0,
      left: 0,
      scrollTrigger: {
        trigger: '.home',
        scrub: 0,
        start: "+=" + this.$root.vh(200),
        end: "+=" + this.$root.vh(250),
      }
    });
    let positionSolutionFirst = {
      left: "20%",
    }
    let positionSolutionSecond = {
      left: "50%",
    }
    let positionSolutionThird = {
      left: "80%",
    }
    if(this.$root.isMobile){
      positionSolutionFirst = {
        top: "25%",
      }
      positionSolutionSecond = {
        top: "55%",
      }
      positionSolutionThird = {
        top: "85%",
      }
    }
    gsap.to(".solution:first-child", {
      ...positionSolutionFirst,
      scrollTrigger: {
        trigger: '.solutions-home',
        scrub: 0,
        start: "+=" + this.$root.vh(100),
        end: "+=" + this.$root.vh(175),
      }
    });
    gsap.to(".solution:nth-child(2)", {
      ...positionSolutionSecond,
      scrollTrigger: {
        trigger: '.solutions-home',
        scrub: 0,
        start: "+=" + this.$root.vh(100),
        end: "+=" + this.$root.vh(175),
      }
    });
    gsap.to(".solution:nth-child(3)", {
      ...positionSolutionThird,
      scrollTrigger: {
        trigger: '.solutions-home',
        scrub: 0,
        start: "+=" + this.$root.vh(100),
        end: "+=" + this.$root.vh(175),
      }
    });

    this.mouseEnterEventImportant = (e) => {
      this.handleMouseEnterImportant.bind(this)(e);
    }
    this.mouseEnterEventSolution = (e) => {
      this.handleMouseEnterSolution.bind(this)(e);
    }
    this.mouseLeaveEvent = (e) => {
      this.handleMouseLeave.bind(this)(e);
    }

    let importants = document.getElementsByClassName("important");
    for(let i = 0; i < importants.length; i++){
      importants[i].addEventListener("mouseenter", this.mouseEnterEventImportant);
      importants[i].addEventListener("mouseleave", this.mouseLeaveEvent);
    }
    let solutions = document.getElementsByClassName("solution");
    for(let i = 0; i < solutions.length; i++){
      solutions[i].addEventListener("mouseenter", this.mouseEnterEventSolution);
      solutions[i].addEventListener("mouseleave", this.mouseLeaveEvent)
    }
  },
  beforeUnmount() {
    let importants = document.getElementsByClassName("important");
    for(let i = 0; i < importants.length; i++){
      importants[i].removeEventListener("mouseenter", this.mouseEnterEventImportant);
      importants[i].removeEventListener("mouseleave", this.mouseLeaveEvent);
    }
    let solutions = document.getElementsByClassName("solution");
    for(let i = 0; i < solutions.length; i++){
      solutions[i].removeEventListener("mouseenter", this.mouseEnterEventSolution);
      solutions[i].removeEventListener("mouseleave", this.mouseLeaveEvent)
    }
  }
}
</script>
