<template>
    <div class="projets-container">
        <div class="no-projets">
            <h1>Nous n'avons encore aucune réalisations...</h1>
            <h2>Mais soyez la première !</h2>
            <router-link class="devis-button" to="/contact">Devis</router-link>
        </div>
    </div>
</template>
<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);

export default {
    name: "ProjetsView",
    methods: {
        handleMouseEnter(e) {
            const navItem = e.currentTarget;
            const navItemBox = navItem.getBoundingClientRect();
            this.$root.mouse.x = Math.round(navItemBox.left + navItemBox.width / 2);
            this.$root.mouse.y = Math.round(navItemBox.top + navItemBox.height / 2);
            this.$root.sticky = true;
            gsap.to('.ball', {
                borderRadius: "20px",
                width: navItemBox.width - 2,
                height: navItemBox.height - 2,
                backgroundColor: "transparent",
                duration: .01,
            })
        },
        handleMouseLeave() {
            this.$root.sticky = false;
            gsap.to('.ball', {
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                duration: .01,
            })
        }
    },
    mounted() {
        let devis = document.getElementsByClassName("devis-button");
        this.mouseEnterEvent = (e) => {
            this.handleMouseEnter.bind(this)(e);
        }
        this.mouseLeaveEvent = (e) => {
            this.handleMouseLeave.bind(this)(e);
        }
        for(let i = 0; i < devis.length; i++){
            devis[i].addEventListener("mouseenter", this.mouseEnterEvent.bind(this));
            devis[i].addEventListener("mouseleave", this.mouseLeaveEvent.bind(this));
        }
    },
    beforeUnmount() {
        let devis = document.getElementsByClassName("devis-button");
        for(let i = 0; i < devis.length; i++){
            devis[i].removeEventListener("mouseenter", this.mouseEnterEvent.bind(this));
            devis[i].removeEventListener("mouseleave", this.mouseLeaveEvent.bind(this));
        }
    }
}
</script>