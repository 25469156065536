<template>
  <app-menu :class="{'active': showMenu}"></app-menu>
  <div :class="{'ball': true, 'active': sticky, 'black': showMenu}"></div>
  <div class="header-logo-app" v-if="this.$route.name !== 'home'">
    <router-link to="/" class="header-logo">
      <span>M.A.D</span>
      <span>AGENCY</span>
    </router-link>
  </div>
  <nav class="header">
    <div class="burger-menu-container" @click="showMenu = !showMenu">
      <div :class="{'header-burger': true, 'open': showMenu}">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </nav>
  <router-view/>
  <footer class="footer">
    <div class="footer-menu">
      <router-link class="hover-link" to="/">Accueil</router-link>
      <router-link class="hover-link" to="/projets">Projets</router-link>
      <router-link class="hover-link" to="/contact">Contact</router-link>
    </div>
    <div class="footer-property">
      Propriété de M.A.D Agency
    </div>
  </footer>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import AppMenu from "@/components/AppMenu";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);

export default {
  data() {
    return{
      showMenu: false,
      sticky: false,
      mouse: { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    }
  },
  components: {
    AppMenu,
  },
  computed: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    gsap.set(".ball", {xPercent: -50, yPercent: -50});

    var ball = document.querySelector(".ball");
    var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    var speed = 0.1;

    var fpms = 60 / 1000;

    var xSet = gsap.quickSetter(ball, "x", "px");
    var ySet = gsap.quickSetter(ball, "y", "px");

    window.addEventListener("mousemove", e => {   
      if(this.sticky){
        return;
      } 
      this.mouse.x = e.x;
      this.mouse.y = e.y;  
    });
    document.querySelector(".burger-menu-container").addEventListener("mouseenter", e => {
      const navItem = e.currentTarget;
      const navItemBox = navItem.getBoundingClientRect();
      this.mouse.x = Math.round(navItemBox.left + navItemBox.width / 2);
      this.mouse.y = Math.round(navItemBox.top + navItemBox.height / 2);
      this.sticky = true;
      gsap.to('.ball', {
        width: "70px",
        height: "70px",
        backgroundColor: "#AB87FF",
        duration: .01,
      })
    });
    document.querySelector(".burger-menu-container").addEventListener("mouseleave", () => {
      this.sticky = false;
      gsap.to('.ball', {
        width: "50px",
        height: "50px",
        backgroundColor: "transparent",
        duration: .01,
      })
    });
    let links = document.getElementsByClassName("hover-link");
    for(let i = 0; i < links.length; i++){
      links[i].addEventListener("mouseenter", e => {
        const navItem = e.currentTarget;
        const navItemBox = navItem.getBoundingClientRect();
        this.mouse.x = Math.round(navItemBox.left + navItemBox.width / 2);
        this.mouse.y = Math.round(navItemBox.top + navItemBox.height / 2);
        this.sticky = true;
        gsap.to('.ball', {
          borderRadius: 0,
          width: navItemBox.width,
          height: navItemBox.height,
          backgroundColor: "#AB87FF",
          duration: .01,
        })
      });
      links[i].addEventListener("mouseleave", () => {
        this.sticky = false;
        gsap.to('.ball', {
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          duration: .01,
        })
      });
    }
    
    gsap.ticker.add((time, deltaTime) => {
      
      var delta = deltaTime * fpms;
      var dt = 1.0 - Math.pow(1.0 - speed, delta); 
      
      pos.x += (this.mouse.x - pos.x) * dt;
      pos.y += (this.mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });
  },
  methods: {
    vh(coef) {
      return window.innerHeight * (coef/100);
    },
    vw(coef) {
      return window.innerWidth * (coef/100);
    }
  }
}
</script>