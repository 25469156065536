<template>
    <div class="contact-container">
        <form class="contact-form" @submit="sendEmail">
            <h2>Formulaire de contact</h2>
            <label class="required">
                Nom <span class="asterisque">*</span><br/>
                <input type="text" required placeholder="Nom" v-model="email.name" class="contact-input"/>
            </label>
            <label class="required">
                Adresse email <span class="asterisque">*</span><br/>
                <input type="email" required placeholder="Adresse email" v-model="email.mail" class="contact-input"/>
            </label>
            <label>
                Type de devis<br/>
                <select v-model="email.type" class="contact-input">
                    <option value="vitrine">Site vitrine</option>
                    <option value="custom">Site personnel</option>
                    <option value="ecommerce">Site e-commerce</option>
                </select>
            </label>
            <label class="required">
                Descriptif <span class="asterisque">*</span><br/>
                <textarea v-model="email.desc" required placeholder="La description de votre projet..." class="contact-input"></textarea>
            </label>
            <button type="submit" class="contact-submit">
                Envoyer
            </button>
        </form>
    </div>
</template>
<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import axios from 'axios';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);

export default{
    name: "contact-view",
    data() {
        return {
            email: {
                name: '',
                mail: '',
                type: 'vitrine',
                desc: '',
            }
        }
    },
    methods: {
        handleMouseEnter(e) {
            const navItem = e.currentTarget;
            const navItemBox = navItem.getBoundingClientRect();
            this.$root.mouse.x = Math.round(navItemBox.left + navItemBox.width / 2);
            this.$root.mouse.y = Math.round(navItemBox.top + navItemBox.height / 2);
            this.$root.sticky = true;
            gsap.to('.ball', {
                borderRadius: "20px",
                width: navItemBox.width - 2,
                height: navItemBox.height - 2,
                backgroundColor: "transparent",
                duration: .01,
            })
        },
        handleMouseLeave() {
            this.$root.sticky = false;
            gsap.to('.ball', {
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                duration: .01,
            })
        },
        sendEmail(e) {

            e.preventDefault();

            let email = {}
            email.html = `<h1>Nouveau message !</h1>
            <p>Nouveau message de ${this.email.name}</p>
            <p>Adresse email: ${this.email.mail}</p>
            <p>Type de demande : ${this.email.type}</p>
            <p>Voici le message :</p>
            ${this.email.desc}`;

            email.text = `${this.email.desc}`;

            email.name = this.email.name;
            email.from = this.email.mail;
            email.subject = "Contact M.A.D Agency !";

            axios.post(`${process.env.VUE_APP_API_URL}/emails`, {
                ...email
            })

            this.email = {
                name: '',
                mail: '',
                type: 'vitrine',
                desc: '',
            }
        }
    },
    mounted() {
        this.mouseEnterEvent = (e) => {
            this.handleMouseEnter.bind(this)(e);
        }
        this.mouseLeaveEvent = (e) => {
            this.handleMouseLeave.bind(this)(e);
        }
        let input = document.getElementsByClassName("contact-input");
        for(let i = 0; i < input.length; i++){
            input[i].addEventListener("mouseenter", this.mouseEnterEvent);
            input[i].addEventListener("mouseleave", this.mouseLeaveEvent);
        }
        let button = document.getElementsByClassName("contact-submit");
        for(let i = 0; i < button.length; i++){
            button[i].addEventListener("mouseenter", this.mouseEnterEvent);
            button[i].addEventListener("mouseleave", this.mouseLeaveEvent);
        }
    },
    beforeUnmount() {
        let input = document.getElementsByClassName("contact-input");
        for(let i = 0; i < input.length; i++){
            input[i].removeEventListener("mouseenter", this.mouseEnterEvent);
            input[i].removeEventListener("mouseleave", this.mouseLeaveEvent);
        }
        let button = document.getElementsByClassName("contact-submit");
        for(let i = 0; i < button.length; i++){
            button[i].removeEventListener("mouseenter", this.mouseEnterEvent);
            button[i].removeEventListener("mouseleave", this.mouseLeaveEvent);
        }
    }
}
</script>